@use 'sass:map';
@use 'src/themes/palette/colors.scss' as *;

:global(#search) {
  top:   calc(var(--header-height) + env(safe-area-inset-top));
}

[data-common-template] :global(#search) {
  top: calc(var(  --header-secondary-height) + env(safe-area-inset-top))
}
