@use 'sass:map';
@use 'src/themes/palette/colors.scss' as *;

.activeButton {
  background: map.get($base, '50') !important;
  color: map.get($base, '1000') !important;
  &:hover {
    background: map.get($base, '50');
    color: map.get($base, '1000');
  }
}
