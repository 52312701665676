@use 'sass:map';
@use 'src/themes/palette/colors.scss' as *;

.root {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/assets/images/placeholderVideo.png");
  background-size: cover;
  border-radius: 8px;
  overflow: hidden;

}

.filter {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.40);
  backdrop-filter: blur(12px);
}

.count {
  color: map.get($base, '0');
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.384px;
  z-index: 3;
}