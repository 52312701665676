:root {
  --header-height: 72px;
  --header-secondary-height: 66px;
  --footer-height: 65px;

  // toastify styles
  --toastify-toast-offset: 1rem;
  --toastify-toast-top: max(var(--toastify-toast-offset), env(safe-area-inset-top));
}

.Toastify__toast {
  width: 100%;
}

.Toastify__toast-theme--light,
.Toastify__toast-theme--colored.Toastify__toast--default {
  padding: 16px 16px 16px 24px;
  border-radius: 8px;
  box-shadow: none;
  text-align: center;
  justify-content: center;

  .Toastify__toast-icon {
    display: none;
  }

  &.Toastify__toast--success {
    border: 1px solid #A1BCF0;
    background: #EDF5FF;
  }

  &.Toastify__toast--error {
    border: 1px solid #D7B7B7;
    background: #FFEDED;
  }
}



// toastify styles
@media only screen and (max-width: 640px) {
  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: var(--toastify-toast-top);
    transform: translate(0);
    left: 1rem;
    right: 1rem;
    width: auto;
  }
}
