* {
  --swiper-pagination-color: #fff;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
}

.globalWrapper {
  .wrapper {
    .pagination {
      position: absolute;
      top: 20px;
      left: 20px;
      bottom: auto;
      right: auto;
      width: auto;
      border-radius: 16px;
      background: rgba(255, 255, 255, 0.20);
      backdrop-filter: blur(10px);
      padding: 5px 8px;
      display: flex;
      gap:6px
    }

    .bullet {
      display: block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #FFF;
      //opacity: 0.2;
      margin: 0 !important;

    }
    .active {
      background-color: #fff;
      opacity: 1;
    }
  }
}

