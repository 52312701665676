//HACK to [fixed] was more important
.header {
  position: fixed !important;
  background-color: transparent !important;
}

.content {
  padding: 0 !important;
}

.container{
  min-height: 100dvh;
  max-height: 100dvh;
  position: relative;
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  &:before {
    content:'';
    position: absolute;
    z-index: 9;
    background-image: url('./../../assets/images/arrows-frame.svg');
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
    background-size: 79%;
  }
}

.video{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  //background-image: url("./../../assets/mockImgaes/Cabernet2021_06.jpg");
  background-size: cover;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  mask: url(./../../assets/images/scanner-frame-mask.svg);
  mask-repeat: no-repeat;
  background: rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(4px);
  mask-position: 50% 50%;
  mask-size: 581%;
}