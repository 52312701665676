@use 'sass:map';
@use 'src/themes/palette/colors.scss' as *;

.app {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100dvh;
  padding: 1rem;
  overflow: hidden;


  &:before {
    content: '';
    position: absolute;
    top: -391px;
    left: -496px;
    bottom: 0;
    right: 0;
    background-image: url("/assets/images/404-bg.svg");
    background-size: 10.2rem;
    background-position: 50vw 50vh;
    transform: translate(211px, 50px);
  }
}
.container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

}

.statusWrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 16px;
  z-index: 10;

  .status {
    font-size: 8rem;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: -3.097px;
    color: map.get($brand, '500');
    background: #ffffff;

  }

  .message {
    position: absolute;
    bottom: -96px;
    color: map.get($brand, '500');
  }
}
